@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import "bootstrap-grid.css";
@import "themes/header";
@import "themes/toast";

:root {
    --colorPrimaryDark : #0C2E9D;
    --colorPrimary     : #2446B5;
    --colorAccent      : #ABABAB;
}

*, *::before, *::after {
    margin     : 0;
    padding    : 0;
    box-sizing : border-box;
}

body {
    margin      : 0;
    padding     : 0;
    font-family : poppins, serif;
    height      : 100vh;
}

.ui.negative.button, .ui.negative.buttons .button {
    background : #560C0C;
}

.ui.positive.button, .ui.positive.buttons .button {
    background : #153E05;
}

.ui.primary.button, .ui.primary.buttons .button {
    background : var(--colorPrimaryDark);
}

.ui.grey.label, .ui.grey.labels .label {
    background    : var(--colorPrimaryDark) !important;;

    white-space   : nowrap;
    overflow      : hidden;
    text-overflow : ellipsis;
}

.h-100 {
    height : 100%;
}

form.form {
    padding : 0;
}

.ui.form textarea, textarea {
    resize    : none;
    font-size : 13px;
}

input, optgroup, select, textarea {
    font-size : 14px;
}

.ui.input > input {
    padding        : 3px 10px;
    line-height    : 28px;
    vertical-align : middle;
}

label.form_label {
    display       : block;
    width         : 100%;
    margin-bottom : 5px;
    color         : #000000;
    font-size     : 14px;
}

input.date_input {
    width          : 100%;
    height         : auto;
    padding        : 2px 8px;
    line-height    : 29px;
    vertical-align : middle;
    border-radius  : 5px;
    border         : solid 1px lightgray;

    &:focus {
        border  : solid 1px #C3C1C1;
        outline : none;
    }
}

/*general table data*/
div.table_container {
    overflow-y : auto;
    overflow-x : auto;
    background : whitesmoke;
    padding    : 0;
    height     : 100%;

    & > table {
        min-width : 100%;
    }
}

.ui.table {
    margin : 0;

    thead {
        position   : sticky !important;
        top        : 0;
        z-index    : 2;
        background : var(--colorPrimaryDark);
    }

    tbody tr {
        &:hover {
            cursor : pointer;
        }

        &.reversed {
            color          : red;
            pointer-events : none;
        }
    }

    thead tr th, tbody tr td {
        vertical-align : middle;
    }

    &.has_total tbody tr:last-child, tbody tr.has_total {
        font-weight : 800;
        background  : rgba(85, 83, 83, 0.5) !important;
    }

    &.inverted {
        &.grey {
            background-color : #E2E2E2 !important;
            color            : #000000 !important;
        }

        &.selectable tbody tr:hover {
            //background : var(--colorPrimary) !important;
            background : rgba(85, 83, 83, 0.5) !important;
            color      : white !important;
        }

        &.striped tbody tr:nth-child(2n), &.striped > tr:nth-child(2n) {
            background-color : #D4D4D4
        }

        tr td {
            border-color : #C2BBBB !important;
        }
    }


    &.compact {
        td {
            padding : 10px 6px;

            &.spanned_color {
                background : #134E03;
                padding    : 0;
                position   : relative;

                div.days_spanned {
                    background : #560C0C;
                    height     : 37.56px;
                }

                div.date {
                    background     : transparent;
                    height         : 37.56px;
                    line-height    : 37.56px;
                    vertical-align : middle;
                    padding        : 0 5px;
                    color          : white;
                    position       : absolute;
                    left           : 0;
                    top            : 0;
                }
            }
        }
    }

    &:not(.structured) {
        thead, tbody tr {
            display      : table;
            width        : 100%;
            table-layout : fixed;
        }
    }

    button.ui.tiny {
        padding : 6px;

        i {
            font-size : 10px;
        }
    }
}

div.main_body {
    display        : flex;
    flex-direction : column;
    height         : 100vh;

    div.content_body {
        flex       : 1;
        background : #F1F1F1;
        position   : relative;

        div.content_container {
            height         : 100%;
            background     : #E1E0E0;
            width          : 100%;
            padding        : 5px 5px;

            display        : flex;
            flex-direction : column;

            position       : absolute;
            top            : 50%;
            left           : 50%;
            transform      : translate(-50%, -50%);

            @media (max-width : 1200px) {
                width : 90%;
            }

            @media (max-width : 1500px) {
                width : 85%;
            }

            @media (min-width : 1500px) {
                width : 75%;
            }

            div.filter_container {
                background     : white;
                height         : auto;
                margin-bottom  : 5px;
                display        : flex;
                flex-direction : row;
                padding        : 5px 5px;

                div.filters {
                    flex : 1;

                    & > div {
                        display      : inline-block;
                        width        : 220px;
                        margin-right : 0.5rem;
                    }
                }

                div.buttons {

                }
            }

            div.module_content {
                background : white;
                flex       : 1;
                padding    : 0.5rem;
                overflow-y : hidden;
            }
        }
    }
}

div.modal_div {
    height         : auto;
    max-height     : 80vh;
    background     : white;
    border-radius  : 0;
    padding        : 5px;
    display        : flex;
    flex-direction : column;

    &.full {
        height : 80vh;
    }

    div.form_header {
        padding                 : 12px 10px;
        background              : var(--colorPrimaryDark);
        border-top-left-radius  : 0;
        border-top-right-radius : 0;
        text-align              : center;
        font-weight             : bold;
        color                   : white;
        margin-bottom           : 5px;
    }
}

div.form_container {
    height         : 100%;
    display        : flex;
    flex-direction : column;

    div.form_input {
        flex       : 1;
        background : whitesmoke;
        padding    : 0.75rem 0.75rem;
        overflow-y : auto;

        .custom-file-input {
            position : relative;
            display  : inline-block;
            width    : 250px;

            .file-input {
                position : absolute;
                left     : 0;
                top      : 0;
                width    : 100%;
                height   : 100%;
                opacity  : 0;
                cursor   : pointer;
            }

            .file-label {
                display          : block;
                background-color : var(--colorPrimaryDark);
                color            : white;
                padding          : 10px;
                text-align       : center;
                border-radius    : 5px;
                cursor           : pointer;
            }
        }
    }

    div.form_buttons {
        background : whitesmoke;
        padding    : 5px 5px;
        margin-top : 5px;
    }
}

.progress_container {
    top        : 0;
    right      : 0;
    left       : 0;
    bottom     : 0;
    position   : fixed;
    z-index    : 50000;
    background : rgba(0, 0, 0, 0.8);

    .progress_window {
        height        : auto;
        width         : 400px;
        background    : transparent;
        //background-color : rgba(255, 255, 255, 0.7);
        position      : absolute;
        top           : 50%;
        left          : 50%;
        text-align    : center;
        transform     : translate(-50%, -50%);
        padding       : 20px 10px;
        border-radius : 10px;

        .progress_spinner {
            margin-left       : calc((100% - 50px) / 2);
            width             : 50px;
            height            : 50px;
            border-radius     : 50%;
            border            : 4px solid rgba(0, 0, 0, 0.2);
            border-left-color : #FFFFFF;
            animation         : spin 1.2s linear infinite;
        }

        .progress_text {
            margin-top : 10px;
            color      : white;
        }

        @keyframes spin {
            0% {
                transform : rotate(0deg);
            }
            100% {
                transform : rotate(360deg);
            }
        }
    }
}

div.login_container {
    background : #DEDEDE;
    height     : 100vh;
    width      : 100%;
    position   : relative;

    div.login_form {
        position         : absolute;
        top              : 50%;
        left             : 50%;
        transform        : translate(-50%, -50%);
        width            : 450px;
        height           : auto;
        background-color : white;
        padding          : 25px 25px;
        box-shadow       : 2px 2px 2px grey;

        img {
            height : 100px;
            width  : 100px;
            margin : 0 calc((100% - 100px) / 2);
        }

        h1 {
            font-size   : 1.8rem;
            text-align  : center;
            line-height : 2.2rem;
        }

        h2 {
            font-size  : 1.5rem;
            text-align : center;
        }
    }
}

div.order_details {
    height     : 100%;
    overflow-y : auto;
    background : whitesmoke;
    padding    : 0.5rem;

    div.orderDetail {
        display       : flex;
        border-bottom : solid 1px #DEDEDE;
        padding       : 10px 10px;

        div.detailHeader {
            width       : 120px;
            color       : #1E1C1C;
            font-weight : bold;
            font-size   : 13px;
        }

        div.detailData {
            flex      : 1;
            color     : #262626;
            font-size : 14px;
        }
    }
}


.calendar {
    width           : 100%;
    margin          : 0 auto;
    border          : 1px solid #DDDDDD;
    border-radius   : 4px;
    overflow        : hidden;
    border-collapse : collapse;

    .calendar-header {
        display          : flex;
        justify-content  : space-between;
        align-items      : center;
        padding          : 10px;
        background-color : #F7F7F7;
        border-bottom    : 1px solid #DDDDDD;

        button {
            background : none;
            border     : none;
            font-size  : 1.2em;
            cursor     : pointer;
        }
    }

    .days-of-week {
        display               : grid;
        grid-template-columns : repeat(7, 1fr);
        background            : var(--colorPrimaryDark);

        .day-name {
            padding       : 10px;
            text-align    : center;
            font-weight   : bold;
            color         : white;
            border        : 1px solid #FFFFFF;
            border-bottom : none;

            &:not(:last-child) {
                border-right : none;
            }
        }
    }

    .days-in-month {
        display               : grid;
        grid-template-columns : repeat(7, 1fr);

        .day {
            border           : 1px solid #8C8888;
            background-color : #E0DCDC;
            border-top       : none;

            &:not(:nth-child(7n)) {
                border-right : none;
            }

            &.empty {
                background : white;
            }

            &:not(.has_data) {
                padding-top    : 15px;
                padding-bottom : 15px;
            }

            div.date {
                text-align  : center;
                font-size   : 1.2rem;
                height      : 1.8rem;
                line-height : 1.8rem;
            }

            &.has_data {
                color      : #FFFFFF;
                background : rgb(46, 47, 46);

                div.ppms, div.orders {
                    text-align : center;

                    & > span {
                        display       : inline-block;
                        height        : 12px;
                        min-width     : 12px;
                        width         : auto;
                        color         : #FFFFFF;
                        font-size     : 7px;
                        margin-left   : 1px;
                        line-height   : 12px;
                        text-align    : center;
                        border-radius : 3px;
                        cursor        : pointer;
                    }
                }

                div.ppms {
                    span.passed {
                        background : #560C0C;
                    }

                    span.completed {
                        background : #0B6D0B;
                    }

                    span.upcoming {
                        background : burlywood;
                    }

                    span.future {
                        background : #A2630E;
                    }
                }

                div.orders {
                    span.rejected {
                        background : #560C0C;
                    }

                    span.pending {
                        background : #A2630E;
                    }

                    span.approved {
                        background : burlywood;
                    }

                    span.opened {
                        background : #55D9B6;
                    }

                    span.progress {
                        background : #0B356D;
                    }

                    span.completed {
                        background : #0B6D0B;
                    }
                }
            }
        }
    }
}

div.statistics_header {
    color       : var(--colorPrimaryDark);
    font-size   : 1.2rem;
    font-weight : 700;
}

div.tabs {
    display        : flex;
    flex-direction : row;
    margin-bottom  : 5px;

    div.tab {
        flex       : 1;
        border     : solid 1px #262020;
        padding    : 5px 5px;
        font-size  : 12px;
        text-align : center;
        background : var(--colorPrimaryDark);
        color      : white;
        cursor     : pointer;

        &.active {
            color       : black;
            background  : white;
            font-weight : 700;
        }
    }
}

.ppm_tab {
    color : #FFFFFF;

    &.completed {
        background : #347C0B;
    }

    &.passed {
        background : #560C0C;
    }

    &.upcoming {
        background : #A2630E;
    }

    &.future {
        background : #1F3DD2;
    }
}
