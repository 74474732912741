.nav-area {
    background : #262626;
    z-index    : 5;

    span.link {
        text-decoration : none;
        color           : #FFFFFF;
        padding         : 10px 10px;
        display         : block;
        cursor          : pointer;
    }

    &:after {
        content : '';
        clear   : both;
        display : block;
    }

    ul {
        list-style : none;
        margin     : 0;

        &.menu {
            width  : 100%;
            margin : 0 auto !important;
        }

        &:first-child {
            & > li {
                & > span.link {
                    padding     : 15px 15px;
                    font-size   : 14px;
                    font-weight : 600;
                }
            }
        }

        li {
            &:hover, &.active {
                & > span.link {
                    background : #FFFFFF;
                    color      : #262626;
                }
            }

            &:hover {
                & > ul {
                    display : block;
                }
            }
        }

        ul {
            position   : absolute;
            padding    : 0;
            min-width  : 280px;
            display    : none;
            top        : 100%;
            left       : 0;
            box-shadow : 0 3px 15px rgba(0, 0, 0, 0.5);
            background : white;

            li {
                position : relative;

                span.link {
                    color       : black;
                    font-weight : 500;
                    font-size   : 13px;
                }

                &:hover, &.active {
                    & > span.link {
                        background : #262626;
                        color      : white;
                    }
                }
            }

            ul {
                top  : 0;
                left : 100%;
            }
        }
    }

    & > ul > li {
        float    : left;
        position : relative;
    }
}
