.toasts-wrapper {
    width      : 300px;
    position   : fixed;
    overflow   : hidden;
    z-index    : 50000;
    max-height : calc(100vh - 10px);
    top        : 0;
    right      : 0;

    .toast {
        width                      : 100%;
        height                     : auto;
        margin-bottom              : 15px;
        animation-name             : fadeInUp;
        animation-duration         : 1s;
        animation-fill-mode        : both;
        border-left                : solid 8px black;
        border-bottom-right-radius : 8px;
        border-top-right-radius    : 8px;
        box-shadow                 : 0 2px 5px rgba(0, 0, 0, 0.2);
        padding                    : 5px 5px;

        &.info {
            background : #0A58CA;
        }

        &.error {
            background : #FF4747;
        }

        &.success {
            background : #0B6D0B;
        }

        &:last-child {
            margin-bottom : 0;
        }

        .toast_content {
            color          : #FFFFFF;
            display        : flex;
            flex-direction : row;

            .toast_header {
                font-weight    : 600;
                font-size      : 13px;
                padding        : 0 6px;
                width          : auto;
                background     : white;
                color          : black;
                border-radius  : 3px;
                height         : 20px;
                line-height    : 20px;
                vertical-align : center;
            }

            span.toast_close {
                font-weight : 600;
                font-size   : 20px;
                cursor      : pointer;
            }
        }

        .toast_message {
            margin-top : 5px;
            color      : #FFFFFF;
            font-size  : 0.95rem;
        }
    }

    @keyframes fadeInUp {
        from {
            opacity           : 0;
            -webkit-transform : translate3d(0, 100%, 0);
            transform         : translate3d(0, 100%, 0);
        }
        to {
            opacity           : 1;
            -webkit-transform : none;
            transform         : none;
        }
    }
}


.toast-header .btn-close {
    margin-right : -0.375rem;
    margin-left  : 0.75rem;
}


